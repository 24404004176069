<template>
    <div id="bindays-page">
        <v-app-bar flat color="transparent" class="main-appbar">
            <app-bar-nav-icon />
            <v-skeleton-loader
                type="chip"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>

            <v-toolbar-title v-else class="headline font-weight-bold"
                >Bin days</v-toolbar-title
            >

            <v-spacer></v-spacer>
            <v-row align="center" style="max-width: 300px">
                <v-text-field
                    flat
                    hide-details
                    solo
                    rounded
                    clearable
                    background-color="grey lighten-3"
                    label="Search name, email or phone number"
                    v-model="filter.search"
                    @keyup.enter="search"
                ></v-text-field>
            </v-row>

            <v-skeleton-loader
                type="actions"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>

            <template v-else>
                <v-btn class="ml-5" icon @click="search">
                    <img src="@/assets/icons/search.svg" alt height="17.7px" />
                </v-btn>
            </template>
        </v-app-bar>
        <v-col class="text-right">
            <ExportBindays v-if="!this.selectedFile" />
            <v-btn
                rounded
                outlined
                color="primary"
                class="ma-2 white--text"
                @click="selectFile"
            >
                {{
                    this.selectedFile
                        ? this.selectedFile.name
                        : 'Import Csv File'
                }}
                <v-icon right dark>
                    {{ icons.upload }}
                </v-icon>
            </v-btn>
            <v-btn
                v-if="this.selectedFile"
                :loading="uploading"
                color="primary"
                class="ma-2 white--text"
                @click="uploadCsv"
            >
                Upload
            </v-btn>
            <input
                ref="uploader"
                class="d-none"
                type="file"
                accept=".csv"
                @change="onFileChanged"
            />
            <p v-if="this.latestUpdatedBy">
                Uploaded By:
                {{
                    this.latestUpdatedBy
                        .toUpperCase()
                        .concat(', ', this.latestUpdate)
                }}
            </p>
            <p class="red--text">
                {{ errors[0] }}
            </p>
            <p class="red--text">
                {{ errors[1] }}
            </p>
        </v-col>
        <strong v-if="importing">importing bindays..... </strong><br />
        <strong v-if="importing"
            >Note: Please reload the page to check the current status</strong
        >
        <v-progress-linear
            v-if="importing"
            color="primary"
            indeterminate
            rounded
            height="25"
        >
            <template v-slot:default="{ value }">
                <strong>
                    {{ importing.imported_count.toLocaleString() }}/
                    {{ importing.import_total_count.toLocaleString() }}
                </strong>
            </template></v-progress-linear
        >
        <v-simple-table class="table-responsive">
            <thead>
                <tr>
                    <th>Street Number</th>
                    <th>Address</th>
                    <th>Suburb</th>
                    <th>Ward</th>
                    <th>Garbage start date</th>
                    <th>Garbage frequency</th>
                    <th>Food and green waste start date</th>
                    <th>Food and green waste frequency</th>
                    <th>Recycling start date</th>
                    <th>Recycling frequency</th>
                    <th>Hard waste start date</th>
                    <th>Hard waste frequency</th>
                </tr>
            </thead>
            <tbody
                v-infinite-scroll="loadMore"
                :infinite-scroll-disabled="loading"
                infinite-scroll-distance="100"
            >
                <tr>
                    <td colspan="4" v-if="unAuthorizedMessage != ''">
                        <div class="display-1 my-6">
                            {{ unAuthorizedMessage }}
                        </div>
                    </td>
                </tr>
                <tr v-for="binday in bindays" :key="binday.id">
                    <td>{{ binday.street_number }}</td>
                    <td>{{ binday.route }}</td>
                    <td>{{ binday.locality }}</td>
                    <td>{{ binday.municipal_subdivision }}</td>
                    <td>{{ binday.general_waste_start_date }}</td>
                    <td>{{ binday.general_waste_frequency }}</td>
                    <td>{{ binday.food_and_green_waste_start_date }}</td>
                    <td>{{ binday.food_and_green_waste_frequency }}</td>
                    <td>{{ binday.recycling_start_date }}</td>
                    <td>{{ binday.recycling_frequency }}</td>
                    <td>{{ binday.hard_waste_start_date }}</td>
                    <td>{{ binday.hard_waste_frequency }}</td>
                </tr>
                <template v-if="loading">
                    <tr v-for="index in 20" :key="index + '-skeleton'">
                        <td class="pl-0">
                            <v-skeleton-loader
                                type="list-item"
                                transition="fade-transition"
                            />
                        </td>
                        <td class="pl-0">
                            <v-skeleton-loader
                                type="list-item"
                                transition="fade-transition"
                            />
                        </td>
                        <td class="pl-0">
                            <v-skeleton-loader
                                type="list-item"
                                transition="fade-transition"
                            />
                        </td>
                        <td class="pl-0">
                            <v-skeleton-loader
                                type="list-item"
                                transition="fade-transition"
                            />
                        </td>
                        <td class="pl-0">
                            <v-skeleton-loader
                                type="list-item"
                                transition="fade-transition"
                            />
                        </td>
                        <td class="pl-0">
                            <v-skeleton-loader
                                type="list-item"
                                transition="fade-transition"
                            />
                        </td>
                        <td class="pl-0">
                            <v-skeleton-loader
                                type="list-item"
                                transition="fade-transition"
                            />
                        </td>
                        <td class="pl-0">
                            <v-skeleton-loader
                                type="list-item"
                                transition="fade-transition"
                            />
                        </td>
                        <td class="pl-0">
                            <v-skeleton-loader
                                type="list-item"
                                transition="fade-transition"
                            />
                        </td>
                        <td class="pl-0">
                            <v-skeleton-loader
                                type="list-item"
                                transition="fade-transition"
                            />
                        </td>
                        <td class="pl-0">
                            <v-skeleton-loader
                                type="list-item"
                                transition="fade-transition"
                            />
                        </td>
                        <td class="pl-0">
                            <v-skeleton-loader
                                type="list-item"
                                transition="fade-transition"
                            />
                        </td>
                    </tr>
                </template>
            </tbody>
        </v-simple-table>
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
            {{ snackbar.message }}
            <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
    mdiDotsVertical,
    mdiUnfoldMoreHorizontal,
    mdiMagnify,
    mdiAccountCircleOutline,
    mdiChevronRight,
    mdiFileExcel,
    mdiPlus,
    mdiFileUploadOutline
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import ExportBindays from './components/ExportBindays.vue'

export default {
    name: 'BinDaysPage',

    mixins: [ControlsMixin],

    directives: {
        infiniteScroll
    },

    components: {
        AppBarNavIcon,
        ExportBindays
    },

    data() {
        return {
            unAuthorizedMessage: '',
            loading: false,
            showSearch: false,
            showProfile: false,
            uploading: false,
            errors: [],
            filter: {
                search: ''
            },
            icons: {
                settings: mdiDotsVertical,
                sort: mdiUnfoldMoreHorizontal,
                search: mdiMagnify,
                view: mdiAccountCircleOutline,
                arrow: mdiChevronRight,
                export: mdiFileExcel,
                plus: mdiPlus,
                upload: mdiFileUploadOutline
            },
            snackbar: {
                show: false,
                message: null,
                color: null
            },
            selectedFile: null
        }
    },

    created() {
        this.clearBinDay()
        this.fetchBinDays(1)
    },

    computed: {
        ...mapState({
            bindays: state => state.binday.list,
            listMeta: state => state.binday.listMeta,
            importing: state => state.binday.importing,
            latestUpdate: state => state.binday.latestUpdate,
            latestUpdatedBy: state => state.binday.latestUpdatedBy
        })
    },

    methods: {
        ...mapActions({
            getBinDays: 'binday/getBinDays',
            uploadCsvFile: 'binday/uploadCsvFile'
        }),
        ...mapMutations({
            clearBinDay: 'binday/clearBinDayList'
        }),
        pageChanged(page) {
            this.fetchBinDays(page)
        },
        async fetchBinDays(page = 1) {
            if (this.loading) return
            let params = { page }
            if (this.filter.search) {
                params.search = this.filter.search
            }
            this.loading = true
            this.getBinDays(params)
                .then(res => {
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    if (err.response.status === 403) {
                        this.unAuthorizedMessage = err.response.data.message
                    }
                })
        },
        loadMore() {
            if (this.listMeta.current_page < this.listMeta.last_page) {
                this.fetchBinDays(this.listMeta.current_page + 1)
            }
        },
        search: debounce(function() {
            this.clearBinDay()
            this.fetchBinDays()
        }, 600),

        showSnackbar(message, color) {
            this.snackbar.message = message
            this.snackbar.color = color
            this.snackbar.show = true
        },
        onFileChanged(e) {
            this.selectedFile = e.target.files[0]
        },

        selectFile() {
            this.$refs.uploader.click()
        },

        uploadCsv() {
            let form = new FormData()
            this.errors = []
            form.append('file', this.selectedFile)
            this.uploading = true
            this.uploadCsvFile(form)
                .then(res => {
                    this.$refs.uploader.value = null
                    this.selectedFile = null
                    this.uploading = false
                    location.reload()
                })
                .catch(errs => {
                    this.uploading = false
                    let response = errs.response
                    if (response.status === 422) {
                        this.errors = response.data.errors.file
                    }
                })
        }
    }
}
</script>
<style lang="scss">
#bindays-page {
    tbody {
        display: block;
        height: 69vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    thead {
        width: calc(100% - 1em);
    }
    table tr {
        td,
        th {
            white-space: nowrap;
            width: 200px;
        }
    }
}
</style>
