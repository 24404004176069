<template>
    <div>
        <v-app-bar flat color="transparent" class="main-appbar">
            <app-bar-nav-icon />
            <v-skeleton-loader
                type="chip"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>

            <v-toolbar-title v-else class="headline font-weight-bold"
                >Manage Bin Details</v-toolbar-title
            >
        </v-app-bar>

        <div class="mt-12 mx-4" id="admin-list">
            <v-simple-table>
                <thead>
                    <tr>
                        <th width="25%" class="text-left pa-0">Bin Name</th>
                        <th width="25%" class="text-left">Last Updated</th>
                        <th width="25%" class="text-left">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="loading">
                        <tr v-for="index in 4" :key="index + '-skeleton'">
                            <td class="py-3 pl-0" width="5%">
                                <v-skeleton-loader
                                    class="avatar-loading--36"
                                    type="avatar"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="2" v-if="unAuthorizedMessage != ''">
                                <div class="display-1 my-6">
                                    {{ unAuthorizedMessage }}
                                </div>
                            </td>
                        </tr>
                        <tr v-for="bin in bins" :key="bin.id" class="pa-2">
                            <td class="">
                                <tr>
                                    <td>
                                        <v-img
                                            v-if="bin.icon"
                                            :src="bin.icon.url"
                                            width="75"
                                        ></v-img>
                                        <v-img
                                            v-else
                                            :src="placeholderImage"
                                            width="75"
                                        ></v-img>
                                    </td>
                                    <td>{{ bin.title }}</td>
                                </tr>
                            </td>

                            <td class="">
                                <span class="updated-on-format">{{
                                    bin.updatedDate
                                }}</span>
                            </td>
                            <td class="">
                                <v-icon
                                    class="me-1"
                                    @click.prevent="
                                        showUpdateBinDetailsDialog(bin.id)
                                    "
                                >
                                    {{ icons.pencil }}
                                </v-icon>
                                <v-icon
                                    v-if="bin.visible"
                                    class="eye-icon"
                                    @click.prevent="
                                        toggleVisibility({ id: bin.id })
                                    "
                                >
                                    {{ icons.eye }}
                                </v-icon>
                                <v-icon
                                    v-else
                                    class="eye-icon"
                                    @click.prevent="
                                        toggleVisibility({ id: bin.id })
                                    "
                                >
                                    {{ icons.eyeOff }}
                                </v-icon>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </v-simple-table>
        </div>

        <UpdateBinDetailsDialog
            v-model="toggleShowUpdateBinDetailsDialog"
            :binInformation="binInformation"
            :key="Math.random()"
        />

        <WhatWeCollectDialog
            v-model="toggleShowWhatWeCollectDialog"
            :binInformation="binInformation"
            :key="Math.random()"
        />

        <WhatWeDoNotCollectDialog
            v-model="toggleShowWhatWeDoNotCollectDialog"
            :binInformation="binInformation"
            :key="Math.random()"
        />

        <MoreInfoSectionDialog
            v-model="toggleShowMoreInfoSectionDialog"
            :binInformation="binInformation"
            :key="Math.random()"
        />

        <v-snackbar v-model="toggleSnackBar" right color="success">
            Update successful
        </v-snackbar>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import { mdiDotsVertical, mdiEye, mdiEyeOff, mdiPencil } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import UpdateBinDetailsDialog from './components/UpdateBinDetailsDialog.vue'
import WhatWeCollectDialog from './components/WhatWeCollectDialog.vue'
import WhatWeDoNotCollectDialog from './components/WhatWeDoNotCollectDialog.vue'
import MoreInfoSectionDialog from './components/MoreInfoSectionDialog.vue'
import articleImagePlaceholder from '@/assets/images/article-image-placeholder.png'

export default {
    directives: {
        infiniteScroll
    },
    mixins: [ControlsMixin],
    components: {
        AppBarNavIcon,
        UpdateBinDetailsDialog,
        WhatWeCollectDialog,
        WhatWeDoNotCollectDialog,
        MoreInfoSectionDialog
    },
    data() {
        return {
            unAuthorizedMessage: '',
            loading: false,
            icons: {
                settings: mdiDotsVertical,
                eye: mdiEye,
                eyeOff: mdiEyeOff,
                pencil: mdiPencil
            },
            binInformation: { id: '-' },
            placeholderImage: articleImagePlaceholder
        }
    },
    methods: {
        ...mapMutations({}),
        ...mapActions({
            getBinsList: 'bindetails/getBinsList',
            toggleVisibility: 'bindetails/toggleVisibility',
            getBinInformation: 'bindetails/getBinInformation'
        }),

        fetchBinsList() {
            if (this.loading) return

            this.loading = true
            this.getBinsList()
                .catch(err => {
                    if (err.response.status === 403) {
                        this.unAuthorizedMessage = err.response.data.message
                    }
                })
                .finally(_ => {
                    this.loading = false
                })
        },

        async showUpdateBinDetailsDialog(binId) {
            this.binInformation = await this.getBinInformation({ id: binId })
            this.toggleShowUpdateBinDetailsDialog = true
        }
    },
    computed: {
        ...mapState({
            bins: state => state.bindetails.list
        }),
        toggleShowUpdateBinDetailsDialog: {
            get: function() {
                return this.$store.state.bindetails.showUpdateBinDetailsDialog
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/toggleShowUpdateBinDetailsDialog',
                    value
                )
            }
        },
        toggleShowWhatWeCollectDialog: {
            get: function() {
                return this.$store.state.bindetails.showWhatWeCollectDialog
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/toggleShowWhatWeCollectDialog',
                    value
                )
            }
        },
        toggleShowWhatWeDoNotCollectDialog: {
            get: function() {
                return this.$store.state.bindetails.showWhatWeDoNotcollectDialog
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/toggleShowWhatWeDoNotcollectDialog',
                    value
                )
            }
        },
        toggleShowMoreInfoSectionDialog: {
            get: function() {
                return this.$store.state.bindetails.showMoreInfoSectionDialog
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/toggleShowMoreInfoSectionDialog',
                    value
                )
            }
        },
        toggleSnackBar: {
            get: function() {
                return this.$store.state.bindetails.openSnackbar
            },
            set: function(value) {
                this.$store.commit('bindetails/toggleSnackbar', value)
            }
        }
    },
    created() {
        this.fetchBinsList()
    }
}
</script>

<style lang="scss" scoped>
.updated-on-format {
    background-color: #d3d3d3;
    border-radius: 4px;
    padding: 5px 10px;
}
.eye-icon:focus {
    outline: none;
    box-shadow: none;
}
</style>
