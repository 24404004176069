<script>
import Form from '@/utils/form'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import { mapActions } from 'vuex'
import articleImagePlaceholder from '@/assets/images/article-image-placeholder.png'
import listItems from '@/utils/formatEditor'
import {
    TiptapVuetify,
    Bold,
    Italic,
    Underline,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    HardBreak,
    Strike,
    History
    // Code,
    // HorizontalRule,
    // Heading,
    // Blockquote,
    // Paragraph,
} from 'tiptap-vuetify'

export default {
    mixins: [SnackbarMixin],
    components: {
        TiptapVuetify
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        binInformation: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            form: new Form({
                id: null,
                title: null,
                description: null,
                icon: null
            }),
            currentIcon: null,
            newIcon: null,
            extensions: [
                History,
                Link,
                Underline,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                Bold,
                Strike,
                HardBreak
                // Code,
                // HorizontalRule,
                // Blockquote,
                // [
                //     Heading,
                //     {
                //         options: {
                //             levels: [1, 2, 3]
                //         }
                //     }
                // ],
                // Paragraph,
            ],
            binInformationCache: null,
            placeholderImage: articleImagePlaceholder,
            invalidImageFormat: false
        }
    },
    methods: {
        ...mapActions({
            updateBinInformation: 'bindetails/updateBinInformation'
        }),
        async submit() {
            this.form.$busy = true
            this.form.description = listItems(this.form.description)

            const data = await this.updateBinInformation(this.form)

            if (data.success) {
                this.$emit('binDetailsUpdated', 'true')
                this.toggleShowUpdateBinDetailsDialog = false
                this.toggleSnackBar = true
            }

            this.form.$busy = false
        },
        selectImage() {
            this.$refs.fileUpload.click()
        },
        updateImage(e) {
            this.form.icon = e.target.files
            const file = e.target.files[0]
            const fileType = file.type.split('/')
            e.target.files = null

            if (!['jpg', 'jpeg', 'png'].includes(fileType[1])) {
                this.invalidImageFormat = true

                setTimeout(() => {
                    this.invalidImageFormat = false
                }, 2000)
            } else if (file) this.newIcon = URL.createObjectURL(file)
        },
        deleteImage() {
            this.newIcon = null
            this.form.icon = null
        },
        openWhatWeCollectDialog() {
            this.toggleShowUpdateBinDetailsDialog = false
            this.toggleShowWhatWeCollectDialog = true
        },
        openWhatWeDoNotCollectDialog() {
            this.toggleShowUpdateBinDetailsDialog = false
            this.toggleShowWhatWeDoNotCollectDialog = true
        },
        openShowMoreInfoSectionDialog() {
            this.toggleShowUpdateBinDetailsDialog = false
            this.toggleShowMoreInfoSectionDialog = true
        }
    },
    computed: {
        toggleShowWhatWeCollectDialog: {
            get: function() {
                return this.$store.state.bindetails.showWhatWeCollectDialog
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/toggleShowWhatWeCollectDialog',
                    value
                )
            }
        },
        toggleShowUpdateBinDetailsDialog: {
            get: function() {
                return this.$store.state.bindetails.showUpdateBinDetailsDialog
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/toggleShowUpdateBinDetailsDialog',
                    value
                )
            }
        },
        toggleShowWhatWeDoNotCollectDialog: {
            get: function() {
                return this.$store.state.bindetails.showWhatWeDoNotcollectDialog
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/toggleShowWhatWeDoNotcollectDialog',
                    value
                )
            }
        },
        toggleShowMoreInfoSectionDialog: {
            get: function() {
                return this.$store.state.bindetails.showMoreInfoSectionDialog
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/toggleShowMoreInfoSectionDialog',
                    value
                )
            }
        },
        updateSuccessful: {
            get: function() {
                return this.$store.state.bindetails.updateSuccessful
            },
            set: function(value) {
                this.$store.commit('bindetails/toggleUpdateSuccessful', value)
            }
        },
        toggleSnackBar: {
            get: function() {
                return this.$store.state.bindetails.openSnackbar
            },
            set: function(value) {
                this.$store.commit('bindetails/toggleSnackbar', value)
            }
        },
        binDetailsSidebarTitle1: {
            get: function() {
                return this.$store.state.bindetails.binDetailsSidebarTitle1
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/updateBinDetailsSidebarTitle1',
                    value
                )
            }
        },
        binDetailsSidebarTitle2: {
            get: function() {
                return this.$store.state.bindetails.binDetailsSidebarTitle2
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/updateBinDetailsSidebarTitle2',
                    value
                )
            }
        },
        binDetailsSidebarTitle3: {
            get: function() {
                return this.$store.state.bindetails.binDetailsSidebarTitle3
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/updateBinDetailsSidebarTitle3',
                    value
                )
            }
        }
    },
    mounted() {
        this.form.id = this.binInformation?.id
        this.form.title = this.binInformation?.title
        this.form.description = this.binInformation?.description
        this.currentIcon = this.binInformation.icon
            ? this.binInformation.icon?.url
            : this.placeholderImage

        if (this.form.id === 4) {
            this.binDetailsSidebarTitle1 = 'What can go out for collection'
            this.binDetailsSidebarTitle2 = "What can't go out for collection"
        } else {
            this.binDetailsSidebarTitle1 = 'What goes in this bin'
            this.binDetailsSidebarTitle2 = "What doesn't go in this bin"
        }

        // For future proofing in case they wish to make this title dynamic
        this.binDetailsSidebarTitle3 = 'More info section'
    }
}
</script>

<style scoped>
.invalid-image {
    color: red;
    font-weight: bold;
}
</style>

<template>
    <div>
        <v-dialog
            @input="$emit('input', $event)"
            :value="value"
            max-width="1000"
        >
            <v-card>
                <v-card-title class="headline">
                    <v-row>
                        <v-col>Update Bin Details</v-col>
                        <v-col align="right"
                            ><small
                                class=""
                                style="color:green;"
                                v-if="updateSuccessful"
                                >Update successful</small
                            ></v-col
                        >
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row class="justify-center align-center">
                            <v-col
                                cols="12"
                                md="6"
                                class="h-m-500 overflow-y-auto hide-scroll-bar"
                            >
                                <v-form ref="form">
                                    <v-row justify="center" align="center">
                                        <v-col>
                                            <div class="d-flex">
                                                <div>
                                                    <input
                                                        ref="fileUpload"
                                                        type="file"
                                                        :input="form.icon"
                                                        @change="updateImage"
                                                        hidden
                                                    />
                                                </div>
                                                <div>
                                                    <v-badge
                                                        v-if="newIcon"
                                                        content="x"
                                                        class="cursor-pointer"
                                                        offset-x="20"
                                                        offset-y="20"
                                                        @click.native="
                                                            deleteImage
                                                        "
                                                    >
                                                        <img
                                                            :src="newIcon"
                                                            alt=""
                                                            class="h-75 cursor-pointer"
                                                            @click.prevent="
                                                                selectImage
                                                            "
                                                        />
                                                    </v-badge>
                                                    <div v-else>
                                                        <img
                                                            :src="currentIcon"
                                                            alt=""
                                                            class="h-75 cursor-pointer"
                                                            @click.prevent="
                                                                selectImage
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div
                                                        class="table-container"
                                                    >
                                                        <div
                                                            class="text-field-label table-item"
                                                            v-html="form.title"
                                                        ></div>
                                                        <div
                                                            v-if="
                                                                invalidImageFormat
                                                            "
                                                            class="invalid-image ps-2 table-item"
                                                        >
                                                            Invalid image
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <label class="text-field-label-light"
                                        >Description</label
                                    >
                                    <tiptap-vuetify
                                        v-model="form.description"
                                        :extensions="extensions"
                                    />
                                </v-form>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col>
                                        <v-card outlined compact>
                                            <v-row
                                                align="center"
                                                justify="center"
                                                class="px-5"
                                            >
                                                <v-col
                                                    class="text-6"
                                                    cols="12"
                                                    md="8"
                                                    >{{
                                                        binDetailsSidebarTitle1
                                                    }}</v-col
                                                >
                                                <v-col cols="12" md="4"
                                                    ><v-btn
                                                        class="float-right"
                                                        outlined
                                                        @click.prevent="
                                                            openWhatWeCollectDialog
                                                        "
                                                        >Update</v-btn
                                                    ></v-col
                                                >
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-card outlined compact>
                                            <v-row
                                                align="center"
                                                justify="center"
                                                class="px-5"
                                            >
                                                <v-col
                                                    class="text-6"
                                                    cols="12"
                                                    md="8"
                                                    >{{
                                                        binDetailsSidebarTitle2
                                                    }}</v-col
                                                >
                                                <v-col cols="12" md="4"
                                                    ><v-btn
                                                        class="float-right"
                                                        outlined
                                                        @click.prevent="
                                                            openWhatWeDoNotCollectDialog
                                                        "
                                                        >Update</v-btn
                                                    ></v-col
                                                >
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-card outlined compact>
                                            <v-row
                                                align="center"
                                                justify="center"
                                                class="px-5"
                                            >
                                                <v-col
                                                    class="text-6"
                                                    cols="12"
                                                    md="8"
                                                    >{{
                                                        binDetailsSidebarTitle3
                                                    }}</v-col
                                                >
                                                <v-col cols="12" md="4"
                                                    ><v-btn
                                                        class="float-right"
                                                        outlined
                                                        @click.prevent="
                                                            openShowMoreInfoSectionDialog
                                                        "
                                                        >Update</v-btn
                                                    ></v-col
                                                >
                                            </v-row>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn
                        color="primary"
                        :loading="form.$busy"
                        @click="submit()"
                        class="w-60 mb-5 mx-5"
                        >Update</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
