<template>
    <v-btn
        rounded
        outlined
        color="primary"
        class="ma-2 white--text"
        :loading="downloading"
        @click="exportBindays"
    >
        export csv file
        <v-icon right dark>
            {{ icons.upload }}
        </v-icon>
    </v-btn>
</template>
<script>
import { mdiFileDownloadOutline } from '@mdi/js'
export default {
    data: () => ({
        icons: {
            upload: mdiFileDownloadOutline
        },
        downloading: false
    }),
    methods: {
        async exportBindays() {
            this.downloading = true
            const response = await this.$store.dispatch('binday/exportToExcel')
            this.downloading = false
            const link = document.createElement('a')
            link.href = response.data
            link.setAttribute('download', 'bindays.csv')
            document.body.appendChild(link)
            link.click()
        }
    }
}
</script>
