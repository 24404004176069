<script>
import Form from '@/utils/form'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import { mapActions } from 'vuex'
import listItems from '@/utils/formatEditor'
import {
    TiptapVuetify,
    Bold,
    Italic,
    Underline,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    HardBreak,
    Strike,
    History
    // Code,
    // HorizontalRule,
    // Heading,
    // Blockquote,
    // Paragraph,
} from 'tiptap-vuetify'

export default {
    mixins: [SnackbarMixin],
    components: {
        TiptapVuetify
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        binInformation: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            form: new Form({
                id: null,
                whatWeCollect: null
            }),
            extensions: [
                History,
                Link,
                Underline,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                Bold,
                Strike,
                HardBreak
                // Code,
                // HorizontalRule,
                // Blockquote,
                // [
                //     Heading,
                //     {
                //         options: {
                //             levels: [1, 2, 3]
                //         }
                //     }
                // ],
                // Paragraph,
            ],
            title: ''
        }
    },
    methods: {
        ...mapActions({
            updateBinInformation: 'bindetails/updateBinInformation'
        }),
        async submit() {
            this.form.$busy = true
            this.form.whatWeCollect = listItems(this.form.whatWeCollect)

            const data = await this.updateBinInformation(this.form)

            if (data.success) {
                this.closeDialog()

                this.updateSuccessful = true
            }

            this.form.$busy = false
        },
        closeDialog() {
            this.toggleShowWhatWeCollectDialog = false
            this.toggleShowUpdateBinDetailsDialog = true
        }
    },
    computed: {
        toggleShowWhatWeCollectDialog: {
            get: function() {
                return this.$store.state.bindetails.showWhatWeCollectDialog
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/toggleShowWhatWeCollectDialog',
                    value
                )
            }
        },
        toggleShowUpdateBinDetailsDialog: {
            get: function() {
                return this.$store.state.bindetails.showUpdateBinDetailsDialog
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/toggleShowUpdateBinDetailsDialog',
                    value
                )
            }
        },
        updateSuccessful: {
            get: function() {
                return this.$store.state.bindetails.updateSuccessful
            },
            set: function(value) {
                this.$store.commit('bindetails/toggleUpdateSuccessful', value)
            }
        }
    },
    mounted() {
        this.form.id = this.binInformation?.id
        this.form.whatWeCollect = this.binInformation?.we_collect
        this.title = this.$store.state.bindetails.binDetailsSidebarTitle1
    }
}
</script>

<template>
    <v-dialog @input="$emit('input', $event)" :value="value" max-width="500">
        <v-card>
            <v-card-title class="headline">{{ title }}</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row class="justify-center align-center">
                        <v-col
                            cols="12"
                            class="h-m-500 overflow-y-auto hide-scroll-bar"
                        >
                            <v-form ref="form">
                                <label class="text-field-label">Content</label>
                                <tiptap-vuetify
                                    v-model="form.whatWeCollect"
                                    :extensions="extensions"
                                />
                            </v-form>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    color="primary"
                    :loading="form.$busy"
                    @click="submit()"
                    class="mb-5"
                    width="25%"
                    >Update</v-btn
                >
                <v-btn
                    color="primary"
                    outlined
                    @click.prevent="closeDialog"
                    class="mb-5"
                    width="25%"
                    >Cancel</v-btn
                >
            </v-card-actions>
            <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
                {{ snackbar.message }}
                <v-btn text @click="snackbar.show = false">OK</v-btn>
            </v-snackbar>
        </v-card>
    </v-dialog>
</template>
