<script>
import Form from '@/utils/form'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import { mapActions } from 'vuex'
import articleImagePlaceholder from '@/assets/images/article-image-placeholder.png'
import listItems from '@/utils/formatEditor'

export default {
    mixins: [SnackbarMixin],
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        binInformation: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            form: new Form({
                id: null,
                linkLabel: null,
                linkUrl: null,
                moreInfoDescription: null,
                moreInfoIcon: null
            }),
            currentIcon: null,
            newIcon: null,
            placeholderImage: articleImagePlaceholder,
            fileName: '',
            invalidImageFormat: false,
            title: ''
        }
    },
    methods: {
        ...mapActions({
            updateBinInformation: 'bindetails/updateBinInformation'
        }),
        async submit() {
            this.form.$busy = true
            this.form.moreInfoDescription = listItems(
                this.form.moreInfoDescription
            )

            const data = await this.updateBinInformation(this.form)

            if (data.success) {
                this.closeDialog()

                this.updateSuccessful = true
            }

            this.form.$busy = false
        },
        closeDialog() {
            this.toggleShowMoreInfoSectionDialog = false
            this.toggleShowUpdateBinDetailsDialog = true
        },
        selectImage() {
            this.$refs.fileUpload.click()
        },
        updateImage(e) {
            this.form.moreInfoIcon = e.target.files
            const file = e.target.files[0]
            const fileType = file.type.split('/')
            e.target.files = null

            if (!['jpg', 'jpeg', 'png'].includes(fileType[1])) {
                this.invalidImageFormat = true

                setTimeout(() => {
                    this.invalidImageFormat = false
                }, 2000)
            } else if (file) {
                this.fileName = file.name
                this.newIcon = URL.createObjectURL(file)
            }
        },
        deleteImage() {
            this.newIcon = null
            this.form.moreInfoIcon = null
            this.fileName = ''
        }
    },
    computed: {
        toggleShowMoreInfoSectionDialog: {
            get: function() {
                return this.$store.state.bindetails.showMoreInfoSectionDialog
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/toggleShowMoreInfoSectionDialog',
                    value
                )
            }
        },
        toggleShowUpdateBinDetailsDialog: {
            get: function() {
                return this.$store.state.bindetails.showUpdateBinDetailsDialog
            },
            set: function(value) {
                this.$store.commit(
                    'bindetails/toggleShowUpdateBinDetailsDialog',
                    value
                )
            }
        },
        updateSuccessful: {
            get: function() {
                return this.$store.state.bindetails.updateSuccessful
            },
            set: function(value) {
                this.$store.commit('bindetails/toggleUpdateSuccessful', value)
            }
        }
    },
    mounted() {
        this.form.id = this.binInformation?.id
        this.form.linkLabel = this.binInformation?.more_info?.link_label
        this.form.linkUrl = this.binInformation?.more_info?.link_url
        this.form.moreInfoDescription = this.binInformation?.more_info?.description
        this.currentIcon = this.binInformation?.more_info?.icon?.url
        this.title = this.$store.state.bindetails.binDetailsSidebarTitle3
    }
}
</script>

<style scoped>
.invalid-image {
    color: red;
    font-weight: bold;
}
</style>

<template>
    <v-dialog @input="$emit('input', $event)" :value="value" max-width="500">
        <v-card>
            <v-card-title class="headline">{{ title }}</v-card-title>
            <div></div>
            <v-card-text>
                <v-container>
                    <v-row class="justify-center align-center">
                        <v-col class="h-m-500 overflow-y-auto hide-scroll-bar">
                            <v-form ref="form">
                                <div class="d-flex">
                                    <label class="text-field-label"
                                        >More info section</label
                                    >
                                    <div
                                        v-if="invalidImageFormat"
                                        class="invalid-image ms-2"
                                    >
                                        (Invalid image)
                                    </div>
                                </div>
                                <div>
                                    <input
                                        ref="fileUpload"
                                        type="file"
                                        :input="form.moreInfoIcon"
                                        @change="updateImage"
                                        hidden
                                    />
                                </div>
                                <div class="d-flex">
                                    <v-badge
                                        v-if="newIcon"
                                        content="x"
                                        class="cursor-pointer"
                                        offset-x="20"
                                        offset-y="20"
                                        @click.native="deleteImage"
                                    >
                                        <img
                                            :src="newIcon"
                                            alt=""
                                            class="h-70 cursor-pointer"
                                        />
                                    </v-badge>
                                    <img
                                        v-else-if="currentIcon"
                                        :src="currentIcon"
                                        alt=""
                                        class="h-70 cursor-pointer"
                                    />
                                    <img
                                        v-else
                                        :src="placeholderImage"
                                        alt=""
                                        class="h-70 cursor-pointer"
                                    />
                                    <v-text-field
                                        outlined
                                        compact
                                        :value="fileName"
                                        readonly
                                        class="ms-1"
                                        placeholder="Select an image"
                                        hide-details
                                    >
                                        <template slot="append">
                                            <v-btn
                                                color="primary"
                                                class="mb-4"
                                                @click.prevent="selectImage"
                                            >
                                                Browse file
                                            </v-btn>
                                        </template>
                                    </v-text-field>
                                </div>
                                <div class="mt-2">
                                    <v-text-field
                                        outlined
                                        compact
                                        placeholder="Description"
                                        hide-details
                                        v-model="form.moreInfoDescription"
                                    />
                                </div>
                                <div class="mt-2">
                                    <v-text-field
                                        outlined
                                        compact
                                        placeholder="Learn More (Label)"
                                        hide-details
                                        v-model="form.linkLabel"
                                    />
                                </div>
                                <div class="mt-2">
                                    <v-text-field
                                        outlined
                                        compact
                                        placeholder="Learn More URL"
                                        hide-details
                                        v-model="form.linkUrl"
                                    />
                                </div>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn
                    color="primary"
                    :loading="form.$busy"
                    @click="submit()"
                    class="mb-5"
                    width="25%"
                    >Update</v-btn
                >
                <v-btn
                    color="primary"
                    outlined
                    @click.prevent="closeDialog"
                    class="mb-5"
                    width="25%"
                    >Cancel</v-btn
                >
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
